import React from "react";
import '../cssComponents/Footer.css';
function Footer(){
    return (
        <footer>
            {/* Your footer content */}
            <p>&copy; 2023 Your Company. All rights reserved.</p>
        </footer>
    )
};
export default Footer;